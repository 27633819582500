import React from 'react'
import UsedEquipmentTemplate from 'templates/used-equipment-template'
import { graphql } from 'gatsby'

const UsedEquipmentManufacturerTemplate = props => {
  return <UsedEquipmentTemplate {...props} />
}

export const pageQuery = graphql`
  query UsedEquipmentManufacturer(
    $category: String!
    $subcategory: String!
    $manufacturer: String!
  ) {
    allMachineFinderEquipment(
      filter: {
        category: { title: { eq: $category } }
        subcategory: { title: { eq: $subcategory } }
        manufacturer: { eq: $manufacturer }
      }
      sort: { fields: [modifiedDate], order: DESC }
    ) {
      nodes {
        engineHoursMeter
        horsepower
        id
        inBargainBin
        inHUT229
        initialPrice
        isNew
        location {
          name
          state
        }
        manufacturer
        meters {
          type
          value
        }
        model
        modelYear
        modifiedDate
        price
        primaryImage {
          large
          regular
        }
        reducedPrice
        separatorHoursMeter
        slug
        stockNumber
        title
      }
    }
  }
`

export default UsedEquipmentManufacturerTemplate
